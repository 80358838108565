import './multiselect.scss';

import { SwapOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { forwardRef, useState } from 'react';

import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';

import type { IInputProps } from '../input';
import Input from '../input';
import OutsideClickHandler from '../outside-click-handler';
import useControls from './hooks/useControls';
import usePopup from './hooks/usePopup';
import useSelect from './hooks/useSelect';

interface IProps extends Omit<IInputProps, 'onChange' | 'value'> {
  options: IMultiselectOptions[];
  selectedValues?: IMultiselectOptions[];
  onChange: (
    value: IMultiselectOptions | null,
    selectedValues: IMultiselectOptions[]
  ) => void;
}

const Multiselect = forwardRef<HTMLInputElement, IProps>(
  ({ selectedValues, options, onChange, isFrozen, ...rest }, forwardRef) => {
    const [focused, setFocused] = useState(false);
    const [focusTriggered, setFocusTriggered] = useState(false);

    const {
      filteredOptions,
      checkedFilter,
      searchValue,
      setSearchValue,
      toggleCheckedFilter
    } = useControls({
      selectedValues,
      options
    });

    const { bindInput, bindOptions, bindOption, selectedIndex } = useSelect({
      selectedValues,
      onChange,
      defaultOptions: filteredOptions,
      setFocused
    });

    const { topPosition, handlePopupToggle } = usePopup({
      focused,
      setFocused,
      listElement: bindOptions.ref.current,
      isFrozen
    });

    const handleAll = () => {
      if (options.length === selectedValues?.length) onChange(null, []);
      else onChange(options[0], options);
    };

    const fieldClassName = [rest.className ?? '', 'multiselect__field'].join(
      ' '
    );

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setSearchValue('');
          setFocused(false);
        }}
      >
        <div className="multiselect">
          <Input
            {...rest}
            ref={forwardRef}
            className={fieldClassName}
            value={bindInput.value ?? ''}
            autoComplete="off"
            endContent={
              <span
                className={`multiselect__arrow ${
                  focused ? 'multiselect__arrow--rotated' : ''
                }`}
              >
                <ChevronDownIcon />
              </span>
            }
            onChange={() => null}
            onKeyDown={(e) => {
              if (e.key === 'Tab') setFocused(false);
              bindInput.onKeyDown(e);
              rest.onKeyDown?.(e);
            }}
            onFocus={(e) => {
              setFocusTriggered(true);
              handlePopupToggle();
              if (focused) setSearchValue('');
              rest.onFocus?.(e);
            }}
            onClick={(e) => {
              if (!focusTriggered) {
                handlePopupToggle();
                if (focused) setSearchValue('');
              }
              setFocusTriggered(false);
              rest.onClick?.(e);
            }}
            isFrozen={isFrozen}
          />
          <div
            className={`multiselect__popup ${
              focused ? 'multiselect__popup--shown' : ''
            } ${topPosition ? 'multiselect__popup--to-top' : ''}`}
          >
            <div className="multiselect__controls">
              <Checkbox
                indeterminate={
                  (selectedValues?.length || 0) > 0 &&
                  options.length !== selectedValues?.length
                }
                checked={options.length === selectedValues?.length}
                onChange={handleAll}
              />
              <input
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="multiselect__search"
                placeholder="Поиск"
              />
              <button
                type="button"
                onClick={toggleCheckedFilter}
                className={`multiselect__filter ${
                  checkedFilter ? 'multiselect__filter--selected' : ''
                } `}
              >
                <SwapOutlined />
              </button>
            </div>

            <ul {...bindOptions} className="multiselect__list">
              {filteredOptions.map((option, index) => (
                <li
                  className={`multiselect__item ${
                    selectedIndex === index ? 'multiselect__item--selected' : ''
                  }`}
                  key={option.label + option.value}
                  {...bindOption}
                >
                  <Checkbox
                    checked={
                      selectedValues?.find(
                        (selected) => selected.value === option.value
                      ) !== undefined
                    }
                  />
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

Multiselect.displayName = 'Multiselect';

export default Multiselect;

export interface IMultiselectOptions {
  value: string;
  label: string;
}
