import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { banksApi } from '@/integrations/index.api';
import { TBank, TBankDto, TBankResponse } from '@/types/bank.type';

type TBanksSlice = {
  isLoading: boolean;
  banks: TBank[];
  total: number;
  bank: TBankResponse | null;
};

const initialState: TBanksSlice = {
  isLoading: false,
  total: 0,
  banks: [],
  bank: null
};

export const getAllBanks = createAsyncThunk(
  'admin/banks',
  async (data: { limit: number; page: number; searchTerm: string }) => {
    try {
      const res = await banksApi.getAdminAllBanks(
        data.limit,
        data.page,
        data.searchTerm
      );
      return {
        total: res.total,
        banks: res.data
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getBankById = createAsyncThunk(
  'admin/getBankId',
  async (bankId: string) => {
    const res = await banksApi.getAdminBankById(bankId);
    return res.data;
  }
);

export const createBank = createAsyncThunk(
  'admin/bank/create',
  async (data: TBankDto) => {
    const res = await banksApi.createAdminBank(data);
    return res;
  }
);

export const updateBank = createAsyncThunk(
  'admin/banks/update',
  async ({ request_id, data }: { request_id: string; data: TBankDto }) => {
    return await banksApi.updateAdminBank(request_id, data);
  }
);

export const adminBanksSlice = createSlice({
  name: 'admin/banks',
  initialState: initialState,
  reducers: {
    resetBankState(state) {
      state.bank = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBanks.fulfilled, (state, action) => {
        state.banks = action.payload.banks;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getAllBanks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBankById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bank = action.payload;
      })
      .addCase(getBankById.pending, (state) => {
        state.isLoading = true;
        state.bank = null;
      })
      .addCase(getBankById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetBankState } = adminBanksSlice.actions;
