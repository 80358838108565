import { AxiosInstance } from 'axios';

import {
  TCreateLeasing,
  TLeasingCompanyResponse,
  TLeasingData
} from '@/types/leasing';

export class LeasingAdminApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllLeasing(
    limit?: number,
    offset?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TLeasingData[];
  }> {
    let path = `admin/leasing_companies?`;
    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (offset) {
      path = path + `&offset=${offset}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }

    const res = await this.api.get(path);
    return res.data;
  }

  public async createLeasing(data: TCreateLeasing) {
    const res = await this.api.post('admin/leasing_companies', {
      ...data,
      integrationTypeId: data.integrationTypeId
    });
    return res.data;
  }

  public async updateAdminLeasing(leasingId: string, data: TCreateLeasing) {
    const res = await this.api.patch(
      `admin/leasing_companies/${leasingId}`,
      data
    );
    return res.data;
  }

  public async getAdminLeasingById(uuid: string) {
    return await this.api.get<TLeasingCompanyResponse>(
      `admin/leasing_companies/${uuid}`
    );
  }
}
