import './error-template.scss';

import React, { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { NavLink, useLocation } from 'react-router-dom';

import { ROUTES } from '@/components/Shared/routes/routes';
import notFoundImage from '@/media/images/not-found.jpg';
import oopsErrorImage from '@/media/images/oops-error.jpg';

interface IProps {
  type: ErrorType;
}

const ERRORS: Record<ErrorType, ErrorsInterface> = {
  '404': {
    image: notFoundImage,
    title: 'Страница не найдена',
    description: [
      'Такой страницы либо уже нет, либо еще нет.',
      'А может быть, вы перешли по неправильной ссылке.'
    ]
  },
  oops: {
    image: oopsErrorImage,
    title: 'Что-то пошло не так',
    description: ['Просим перезагрузить страницу или']
  }
};

const ErrorTemplate: React.FC<IProps> = ({ type }) => {
  const location = useLocation();
  const { resetBoundary } = useErrorBoundary();

  useEffect(() => {
    return () => {
      resetBoundary();
    };
  }, [resetBoundary, location]);

  return (
    <div className="error-template">
      <img
        className="error-template__image"
        src={ERRORS[type].image}
        alt={`${type}-image`}
      />

      <p className="error-template__title">{ERRORS[type].title}</p>
      <p className="error-template__description">
        {ERRORS[type].description.map((item) => (
          <React.Fragment key={item}>
            {item} <br />
          </React.Fragment>
        ))}
        <NavLink className="error-template__link" to={ROUTES.autoCredit}>
          Перейти на главную
        </NavLink>
      </p>
    </div>
  );
};

export default ErrorTemplate;

type ErrorType = '404' | 'oops';

interface ErrorsInterface {
  image: string;
  title: string;
  description: string[];
}
