import { AxiosInstance } from 'axios';

import { TCreateProductAdminDto, TProduct } from '@/types/product.type';

export class ProductsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminProducts(
    limit: number,
    page: number,
    searchTerm: string
  ): Promise<{
    total: number;
    data: TProduct[];
  }> {
    let path = `admin/products?`;
    if (limit) {
      path += `limit=${limit}`;
    }
    if (page) {
      path += `&page=${page}`;
    }
    if (searchTerm) path += `&searchTerm=${searchTerm}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async createAdminProduct(product: TCreateProductAdminDto) {
    const res = await this.api.post('admin/products', product);
    return res.data;
  }

  public async updateAdminProduct(
    productId: string,
    data: TCreateProductAdminDto
  ) {
    const res = await this.api.patch(`admin/products/${productId}`, data);
    return res.data;
  }

  public async getAdminProductById(productId: string) {
    return await this.api.get(`admin/products/${productId}`);
  }

  public async createPromoProductModels(data: {
    promo_product_uuid: string;
    brand_uuid: string;
    model_uuid: string;
  }) {
    const res = await this.api.post('admin/promo-product-models/create', data);

    return res.data;
  }
}
