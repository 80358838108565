import './styles/App.scss';

import DisableDevtool from 'disable-devtool';
import { jwtDecode } from 'jwt-decode';
import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Litro } from '@/components/App/LitroPnd/Litro';
import { LitroForm } from '@/components/App/LitroPnd/LitroForm/LitroForm';
import BiometricScan from '@/components/BiometricScan/BiometricScan';
import { useAppDispatch, useAppSelector } from '@/store';

import { Layout } from './components/Layout/Layout';
import CheckAuth from './components/Shared/routes/check-auth';
import { ProtectedRoute } from './components/Shared/routes/private-route';
import { ADMIN_ROUTES, ROUTES } from './components/Shared/routes/routes';
import SuspenseRoute from './components/Shared/routes/suspense-route';
import { setNavigate } from './services/helpers/navigate-helper';
import { startNotification } from './store/notifications.slice';

const LoginPage = lazy(() => import('./components/Auth/login/login'));
const ResetPassword = lazy(() => import('./components/Auth/ResetPassword'));
const NotFound = lazy(() => import('./components/Layout/errors/404'));

const AdminRoutes = lazy(() => import('./components/AdminPanel/admin-routes'));
const MainPage = lazy(() => import('./components/App/MainPage/MainPage'));
const Personal = lazy(() => import('./components/App/Personal/Personal'));
const FAQ = lazy(() => import('./components/App/faq/main/faq'));
const FAQTemplate = lazy(
  () => import('./components/App/faq/faq-template/faq-template')
);

const AutoCredit = lazy(() => import('./components/App/AutoCred/auto-credit'));
const NewOrder = lazy(() => import('./components/App/NewOrder/NewOrder'));
const ExistedOrder = lazy(
  () => import('./components/App/NewOrder/ExistedOrder')
);
const Calculator = lazy(() => import('./components/App/Calculator/Calculator'));

const Insurance = lazy(() => import('./components/App/Insurance/Insurance'));
const CreateInsurance = lazy(
  () => import('./components/App/Insurance/insurance-order/create-insurance')
);
const InsuranceOrder = lazy(
  () => import('./components/App/Insurance/insurance-order/insurance-order')
);

const Clients = lazy(
  () => import('./components/App/Clients/clients-list/clients')
);
const ClientInfo = lazy(
  () => import('./components/App/Clients/client-info/client-info')
);

const Leasing = lazy(() => import('./components/App/Leasing/Leasing'));
const CreateLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/create-leasing')
);
const ExistedLeasing = lazy(
  () => import('./components/App/NewLeasing/create-leasing/existed-leasing')
);

const Reports = lazy(() => import('./components/App/Reports/Reports'));
const Approvement = lazy(
  () => import('./components/App/Approvement/approvement')
);

const PosCredit = lazy(
  () => import('./components/App/PosCred/PosCreds/pos-creds')
);
const PosCreditApplication = lazy(
  () =>
    import('./components/App/PosCred/PosCredApclication/pos-cred-application')
);

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isUserAuth } = useAppSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Set global navigate to access from everywhere
  setNavigate(navigate);

  useEffect(() => {
    dispatch(startNotification());
  }, [location.pathname]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const user: { actions: number[] } = jwtDecode(token);
      if (user.actions.includes(0)) {
        setIsAdmin(true);
      } else {
        DisableDevtool({
          disableMenu: false,
          ignore: ['/login', /\/reset_password\/.+/]
        });
      }
    }
  }, [isUserAuth]);

  return (
    <>
      <BiometricScan />
      <Routes>
        <Route element={<CheckAuth />}>
          <Route path="/" element={<Layout />}>
            <Route path="/*" element={<NotFound />} />

            <Route
              element={
                <ProtectedRoute
                  redirectPath={ROUTES.login}
                  isAllowed={isUserAuth}
                />
              }
            >
              <Route path={ROUTES.home} element={<MainPage />} />
              <Route path={ROUTES.faq} element={<FAQ />} />
              <Route path={ROUTES.faqTemplate()} element={<FAQTemplate />} />

              <Route path={ROUTES.autoCredit} element={<AutoCredit />} />
              <Route path={ROUTES.autoCreditCreate} element={<NewOrder />} />
              <Route
                path={ROUTES.autoCreditRequest()}
                element={<ExistedOrder />}
              />
              <Route path={ROUTES.calculator} element={<Calculator />} />
              <Route path={ROUTES.insurances} element={<Insurance />} />
              <Route
                path={ROUTES.insurancesCreate}
                element={<CreateInsurance />}
              />
              <Route
                path={ROUTES.insurancesRequest()}
                element={<InsuranceOrder />}
              />
              <Route path={ROUTES.personal} element={<Personal />} />
              <Route path={ROUTES.settlement} element={<></>} />
              <Route path={ROUTES.leasing} element={<Leasing />} />
              <Route path={ROUTES.leasingCreate} element={<CreateLeasing />} />
              <Route
                path={ROUTES.leasingRequest()}
                element={<ExistedLeasing />}
              />
              <Route path={ROUTES.clients} element={<Clients />} />
              <Route path={ROUTES.client()} element={<ClientInfo />} />
              <Route path={ROUTES.litro} element={<Litro />} />
              <Route path={ROUTES.litroForm} element={<LitroForm />} />
              <Route path={ROUTES.reports} element={<Reports />} />
              <Route path={ROUTES.editor} element={<></>} />
              <Route path={ROUTES.posCredit} element={<PosCredit />} />
              <Route
                path={ROUTES.posCreditRequest()}
                element={<PosCreditApplication />}
              />
              <Route
                path={ROUTES.posCreditCreate()}
                element={<PosCreditApplication />}
              />
              {isAdmin && (
                <Route
                  path={`${ADMIN_ROUTES.root}/*`}
                  element={<AdminRoutes />}
                />
              )}
            </Route>
          </Route>

          <Route element={<SuspenseRoute />}>
            <Route path={ROUTES.login} element={<LoginPage />} />
          </Route>
          <Route element={<SuspenseRoute />}>
            <Route path={ROUTES.resetPassword()} element={<ResetPassword />} />
          </Route>
        </Route>

        <Route element={<SuspenseRoute />}>
          <Route path={ROUTES.approvements} element={<Approvement />} />
        </Route>
      </Routes>
    </>
  );
};
