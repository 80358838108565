import '@/styles/CustomInput.scss';

import { TextField } from '@mui/material';

export type TCustomInputProps = {
  isFocused?: boolean;
  value: string | boolean | Date | null | undefined | number | null;
  label: string;
  type: string;
  htmlType?: string;
  labelTitle: string;
  onChangeInputHandler?: (val: string, label: string, type: string) => void;
  onClickInputHandler?: () => void;
  handleInputChange?: (val: string, label: string, type: string) => void;
  handlePersonalInputChange?: (
    val: string,
    label: string,
    type: string
  ) => void;
  handleAutoInputChange?: (val: string, label: string, type: string) => void;
  handleInsuranceInputChange?: (
    val: string,
    label: string,
    type: string
  ) => void;
  fieldsReadOnly?: boolean;
  disabled?: boolean;
  error?: string;
};

export const CustomInput = ({
  value,
  label,
  type,
  labelTitle,
  htmlType,
  onChangeInputHandler,
  onClickInputHandler,
  handleInputChange,
  handlePersonalInputChange,
  handleAutoInputChange,
  handleInsuranceInputChange,
  fieldsReadOnly,
  disabled = false, // По умолчанию false
  error
}: TCustomInputProps) => {
  return (
    <div className="customItem">
      <TextField
        inputProps={
          htmlType === 'number'
            ? {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                step: '0.1'
              }
            : undefined
        }
        type={htmlType}
        id="outlined-controlled"
        sx={{ width: '100%' }}
        autoComplete={'new-password'}
        name={new Date().toLocaleString() + `${Math.random()}`}
        label={
          <p
            style={{
              color: '#687588',
              lineHeight: '160%',
              fontWeight: '400'
            }}
          >
            {labelTitle}{' '}
            {labelTitle !== 'Номер квартиры' &&
              labelTitle !== 'Номер офиса' &&
              labelTitle !== 'Регистрационный номер авто' && (
                <span style={{ color: '#E03137' }}> *</span>
              )}
          </p>
        }
        variant="outlined"
        disabled={disabled}
        value={value ? value.toString() : ''}
        onChange={(e) => {
          onChangeInputHandler?.(e.target.value, label, type);
          handleInputChange?.(e.target.value, label, type);
          handlePersonalInputChange?.(e.target.value, label, type);
          handleAutoInputChange?.(e.target.value, label, type);
          handleInsuranceInputChange?.(e.target.value, label, type);
        }}
        onClick={onClickInputHandler}
        InputProps={{
          readOnly: fieldsReadOnly !== undefined ? fieldsReadOnly : false,
          autoComplete: 'new-password'
        }}
        error={!!error}
      />
      {error && <span className="customItem__error">{error}</span>}
    </div>
  );
};
