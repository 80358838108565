import { match } from 'path-to-regexp';

import { INNER_PAGES } from '../constants/inner-pages';
import { pageTitles } from '../constants/pages-titles';

export const getPageInfo = (pathname: string) => {
  for (const path in pageTitles) {
    const matchPath = match(path, { decode: decodeURIComponent });
    if (matchPath(pathname)) {
      return pageTitles[path];
    }
  }
  return '';
};

export const shouldShowBackArrow = (pathname: string) => {
  for (const path of INNER_PAGES) {
    const matchPath = match(path, { decode: decodeURIComponent });
    if (matchPath(pathname)) {
      return true;
    }
  }
  return false;
};
