import { AxiosInstance } from 'axios';

import {
  TBrand,
  TCreateBrand,
  TCreateEquipment,
  TEquipment,
  TModel,
  TModelCreate
} from '@/types/model.type';

export class ModelsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getBrands(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TBrand[];
  }> {
    let path = `admin/brands?`;
    if (limit) path = path + `limit=${limit}`;

    if (page) path = path + `&page=${page}`;

    if (searchTerm) path = path + `&searchTerm=${searchTerm}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async getModels(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TModel[];
  }> {
    let path = `admin/models?`;
    if (limit) path = path + `limit=${limit}`;

    if (page) path = path + `&page=${page}`;

    if (searchTerm) path = path + `&searchTerm=${searchTerm}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async getEquipments(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TEquipment[];
  }> {
    let path = `admin/equipments?`;
    if (limit) path = path + `limit=${limit}`;

    if (page) path = path + `&page=${page}`;

    if (searchTerm) path = path + `&searchTerm=${searchTerm}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async createModels(data: TModelCreate) {
    const res = await this.api.post('admin/models', {
      ...data
    });
    return res.data;
  }

  public async createBrands(data: TCreateBrand) {
    const res = await this.api.post('admin/brands', data);
    return res.data;
  }

  public async createEquipments(data: TCreateEquipment) {
    const res = await this.api.post('admin/equipments', data);
    return res.data;
  }

  public async updateBrands(brandsId: string, data: TCreateBrand) {
    const res = await this.api.patch(`admin/brands/${brandsId}`, data);
    return res.data;
  }
  public async getAdminBrandId(uuid: string) {
    return await this.api.get(`admin/brands/${uuid}`);
  }

  public async updateModels(modelsId: string, data: TModelCreate) {
    const res = await this.api.patch(`admin/models/${modelsId}`, data);
    return res.data;
  }
  public async getAdminModelId(uuid: string) {
    return await this.api.get(`admin/models/${uuid}`);
  }

  public async updateEquipments(equipmentId: string, data: TCreateEquipment) {
    const res = await this.api.patch(`admin/equipments/${equipmentId}`, data);
    return res.data;
  }
  public async getAdminEquipmentId(uuid: string) {
    return await this.api.get(`admin/equipments/${uuid}`);
  }
}
