const splitThousands = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const parsePrice = (num: string): number => {
  return parseFloat(num.replace(/\$\s?|( *)/g, '').replace(',', '.'));
};

export const formatPrice = (num: number | string, d: number = 2): string => {
  if (!num) return '';
  const numStr: string = typeof num === 'number' ? num.toString(10) : num;
  const [integer, fraction] = numStr.split('.');
  let fractionPart: string;
  if (!fraction) {
    fractionPart = '';
  } else if (fraction && fraction.length <= d) {
    fractionPart = `,${fraction}`;
  } else {
    fractionPart = `,${parseFloat(numStr).toFixed(d).split('.')[1]}`;
  }
  return splitThousands(integer) + fractionPart;
};

export const unformatPrice = (value: string) => {
  return value.replace(/\s/g, '');
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const cleanedNumber = value.replace(/\D/g, '');
  const formattedNumber = `+7(${cleanedNumber.slice(
    1,
    4
  )})-${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7, 11)}`;
  return formattedNumber;
};

export const formatPhoneNumberWithSpace = (value: string) => {
  if (!value) return value;
  const cleanedNumber = value.replace(/\D/g, '');
  const formattedNumber = `+7 (${cleanedNumber.slice(
    1,
    4
  )}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(
    7,
    9
  )}-${cleanedNumber.slice(9, 11)}`;
  return formattedNumber;
};

export const formatLastName = (value: string): string => {
  const cleanedValue = value.replace(
    /[^a-zA-Zа-яА-ЯёЁӘәҒғҚқҢңӨөҰұҮүҺһІі\- ]/g,
    ''
  );
  return cleanedValue;
};

export const cleanPhoneNumber = (value: string) => {
  const cleanedNumber = value.replace(/\D/g, '');
  return cleanedNumber;
};

export const cleanNameValue = (value: string) => {
  const cleanedNameValue = value.replace(/\d+/g, '').trim().toLowerCase();
  return cleanedNameValue;
};

export const cleanFullNameValue = (value: string) => {
  const cleanedNameValue = value
    .replace(/[^а-яё\s]/gi, '')
    .replace(/\s{2,}/g, ' ')
    .trimStart();
  const capitalizedValue = cleanedNameValue
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return capitalizedValue;
};
