import { TDealerDto, TDealerResponse } from '@/types/dealer.type';

export const mapDealerData = (item: TDealerResponse): TDealerDto => {
  return {
    uuid: item.uuid || '',
    name: item.name || '',
    name_eurasian: item.name_eurasian || null,
    physical_address: item.physical_address || '',
    workpoint_name: item.workpoint_name || '',
    sold_brands: item.sold_brands || '',
    legal_address: item.legal_address || '',
    is_active: item.is_active ? 'active' : 'disabled',
    has_commercial: item.has_commercial ? 'yes' : 'no',
    halyk_dealer_id: item.halyk_dealer_id || null,
    forte_id: item.forte_id || null,
    code: item.code || '',
    city_uuid: item?.city_uuid || '',
    city: item?.city || undefined,
    bin: item.bin || '',
    Dealers_Banks: item.Dealers_Banks.map((bank) => ({
      //   uuid: bank.uuid || '',
      bank_uuid: bank.bank_uuid || '',
      is_active: bank.is_active ? 'active' : 'disabled',
      custom_timeout: bank.custom_timeout || '',
      is_priority_timeout: bank.is_priority_timeout ? 'yes' : 'no'
    })),
    Dealers_Products: item.Dealers_Products.map((product) => ({
      //   uuid: product.uuid || '',
      name: product.name || '',
      bank: product.bank || '',
      product_uuid: product.product_uuid || '',
      is_active: product.is_active ? 'active' : 'disabled'
    }))
  };
};
