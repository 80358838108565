import '@/styles/Modal.scss';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Input } from 'antd';
import { FC, useEffect, useState } from 'react';

import CustomButtonMui from '@/components/Shared/Button/CustomButtonMui';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowVerifySecondStep } from '@/store/app.slice';
import { setPageTitle } from '@/store/newOrder.slice';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: '#FFFFFF',
  borderRadius: '16px',
  padding: '32px',
  fontFamily: 'IBM Plex Sans',
  p: 4
};

const backDropStyles: any = {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  zIndex: '101',
  justifyContent: 'center',
  right: '0',
  bottom: '0',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
};

type TTransitionsModalLitroProps = {
  isLoading: boolean;
  sendValidateOtp: (code: string) => void;
  headerTitle?: string;
};

export const SecondStepVerify: FC<TTransitionsModalLitroProps> = ({
  sendValidateOtp,
  isLoading,
  headerTitle
}) => {
  const dispatch = useAppDispatch();
  const { showVerifySecondStep } = useAppSelector((state) => state.app);
  // const { otpValid } = useAppSelector((state) => state.orderStep);
  const [otpCode, setOtpCode] = useState('');

  const [overWatchValue, setOverWatchValue] = useState<number>(59);

  useEffect(() => {
    if (otpCode.length === 4) {
      sendValidateOtp(otpCode);
      dispatch(setPageTitle(headerTitle));
    }
  }, [otpCode]);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (showVerifySecondStep && overWatchValue > 0) {
        setOverWatchValue((prevState) => prevState - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    // Очищаем интервал при размонтировании компонента или изменении зависимостей
    return () => {
      clearInterval(intervalId);
    };
  }, [showVerifySecondStep, overWatchValue]);

  useEffect(() => {
    if (showVerifySecondStep) {
      const inputElement = document.querySelector('input');
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [showVerifySecondStep]);

  const handleOTPChange = (value: string) => {
    const numericValue = value.replace(/[^0-9]/gi, '');
    setOtpCode(numericValue.trim());
  };

  return (
    <div style={backDropStyles}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableEscapeKeyDown={true}
        hideBackdrop={true}
        open={showVerifySecondStep}
        keepMounted
        onClose={() => dispatch(setShowVerifySecondStep(false))}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={true}>
          <Box sx={style}>
            <IconButton
              onClick={() => {
                dispatch(setShowVerifySecondStep(false));
              }}
              style={{
                height: '32px',
                width: '32px',
                top: '16px',
                right: '16px',
                position: 'absolute'
              }}
            >
              <CloseIcon style={{ height: '32px' }} />
            </IconButton>
            {isLoading ? (
              <Stack
                spacing={3}
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: '15px' }}
              >
                <CircularProgress />
                <Typography
                  fontWeight="400"
                  fontSize="14px"
                  color="#687588"
                  height="20px"
                  align="center"
                >
                  Подождите...
                </Typography>
              </Stack>
            ) : (
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Input.OTP
                  style={{ marginTop: '30px' }}
                  onKeyDown={(e) => {
                    if (
                      (e.key >= '0' && e.key <= '9') ||
                      e.key === 'ArrowLeft' ||
                      e.key === 'ArrowRight' ||
                      e.key === 'Backspace' ||
                      e.key === 'Delete'
                    ) {
                      return;
                    } else {
                      e.preventDefault();
                    }
                  }}
                  size="large"
                  formatter={(value) => {
                    return value.replace(/[^0-9]/gi, '').trim();
                  }}
                  length={4}
                  value={otpCode}
                  onChange={(value) => handleOTPChange(value)}
                />
              </Stack>
            )}
            <Stack spacing={3}>
              {!isLoading ? (
                <div style={{ margin: '30px 0' }}>
                  <Typography
                    fontWeight="400"
                    fontSize="14px"
                    color="#687588"
                    height="20px"
                    align="center"
                  >
                    Отправить код повторно через 00:
                    {overWatchValue > 9 ? overWatchValue : `0${overWatchValue}`}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize="14px"
                    color="#111827"
                    height="22px"
                    align="center"
                    style={{
                      textDecoration: 'underline',
                      textUnderlineOffset: '3px',
                      cursor: 'pointer'
                    }}
                  >
                    Отправить код еще раз
                  </Typography>{' '}
                </div>
              ) : null}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <CustomButtonMui
                  onClick={() => dispatch(setShowVerifySecondStep(false))}
                  title="Отмена"
                  width="100%"
                  action="cancel"
                />
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
