import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { UpdatePosCredRequestInterface } from '@/integrations/adminApi/posCredApi';
import { posCredApi } from '@/integrations/index.api';

import { posCredUserAdapter } from './adapter/orderUserAdapter';
import { InitialStateInterface } from './types/slice-types';

const initialState: InitialStateInterface = {
  posCredUser: {
    data: null,
    status: 'idle',
    error: null
  },
  setPosCredState: {
    status: 'idle',
    error: null
  },
  acceptOfferState: {
    status: 'idle',
    error: null
  },
  refundOfferState: {
    status: 'idle',
    error: null
  }
};

export const getPosCredById = createAsyncThunk(
  'posCred/getPosCredById',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await posCredApi.getPosCredById(payload);
      const newDate = posCredUserAdapter(response.data);

      return newDate;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const onAcceptPosCredOffer = createAsyncThunk(
  'posCred/onAcceptPosCredOffer',
  async (
    { requestUuid, offerUuid }: { requestUuid: string; offerUuid: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await posCredApi.acceptOffer(offerUuid);
      await dispatch(getPosCredById(requestUuid));
      return response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const onRefundPosCredOffer = createAsyncThunk(
  'posCred/onRefundPosCredOffer',
  async (
    {
      request_uuid,
      offer_uuid
    }: {
      request_uuid: string;
      offer_uuid: string;
    },
    { dispatch }
  ) => {
    const response = await posCredApi.refundOffer(request_uuid, offer_uuid);
    dispatch(getPosCredById(request_uuid));

    return response.data;
  }
);

export const setPosCredData = createAsyncThunk(
  'posCred/setPosCredData',
  async (
    payload: UpdatePosCredRequestInterface,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await posCredApi.updatePosCred(payload);
      dispatch(getPosCredById(payload.request_uuid));
      payload.setStep();

      return response.data;
    } catch (error: any) {
      notification.error({ message: 'Ошибка !' });
      rejectWithValue(error);
    }
  }
);

export const posCredApplicationSlice = createSlice({
  name: 'posCredApplication',
  initialState,
  reducers: {
    resetPosCred: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosCredById.pending, (state) => {
        state.posCredUser.status = 'pending';
        state.posCredUser.error = null;
      })
      .addCase(getPosCredById.fulfilled, (state, action) => {
        state.posCredUser.status = 'fulfilled';
        state.posCredUser.data = action.payload || null;
      })
      .addCase(getPosCredById.rejected, (state, action) => {
        state.posCredUser.status = 'rejected';

        state.posCredUser.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(setPosCredData.pending, (state) => {
        state.setPosCredState.status = 'pending';
        state.setPosCredState.error = null;
      })
      .addCase(setPosCredData.fulfilled, (state) => {
        state.setPosCredState.status = 'fulfilled';
      })
      .addCase(setPosCredData.rejected, (state, action) => {
        state.setPosCredState.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(onAcceptPosCredOffer.pending, (state) => {
        state.acceptOfferState.status = 'pending';
        state.acceptOfferState.error = null;
      })
      .addCase(onAcceptPosCredOffer.fulfilled, (state) => {
        state.acceptOfferState.status = 'fulfilled';
        state.acceptOfferState.error = null;
      })
      .addCase(onAcceptPosCredOffer.rejected, (state, action) => {
        state.acceptOfferState.status = 'rejected';
        state.acceptOfferState.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(onRefundPosCredOffer.pending, (state) => {
        state.refundOfferState.status = 'pending';
        state.refundOfferState.error = null;
      })
      .addCase(onRefundPosCredOffer.rejected, (state, action) => {
        state.refundOfferState.status = 'rejected';
        state.refundOfferState.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(onRefundPosCredOffer.fulfilled, (state) => {
        state.refundOfferState.status = 'fulfilled';
        state.refundOfferState.error = null;
      });
  }
});

export const { resetPosCred } = posCredApplicationSlice.actions;
