import './style.scss';

import { Avatar, Skeleton } from 'antd';
import React, { ReactNode } from 'react';

import InfoCircle from '@/media/svg/info-circle.svg?react';

import { Button, InfoBadge } from '..';
import { STATUSES_ENUM } from '../../constants/statuses';

interface HeaderActionInterface<T> {
  onClick: (value: T) => void;
  text: string;
}

type OfferStatus = keyof typeof STATUSES_ENUM;

interface OfferCardProps {
  status?: OfferStatus;
  statusText?: string;
  isSelected?: boolean;
  extId?: string;
  children: ReactNode;
  className?: string;
}

const OfferCard: React.FC<OfferCardProps> & {
  Header: typeof Header;
  Body: typeof Body;
} = ({ children, extId, status, statusText, isSelected, className }) => {
  const offerCardSelected = isSelected ? ` offer-card--selected` : '';
  const idStatusClass = status ? `offer-card__id--${status}` : '';
  const statusClass = status ? `offer-card__status--${status}` : '';

  const classes = [
    'offer-card',
    offerCardSelected,
    !isSelected && status === 'processing' ? ' offer-card--processing' : '',
    className ? ` ${className}` : ''
  ].join('');

  return (
    <div className={classes}>
      {status && (
        <div className={`offer-card__status ${statusClass}`}>
          <p>{statusText}</p>
        </div>
      )}
      {extId && (
        <div className={`offer-card__id ${idStatusClass}${offerCardSelected} `}>
          <p>{extId}</p>
        </div>
      )}
      {children}
    </div>
  );
};

interface HeaderProps {
  icon?: string;
  title: string;
  status?: OfferStatus;
  isWaitingOffer?: boolean;
  info?: string;
  descriptionAction?: HeaderActionInterface<unknown>;
  headerActions?: JSX.Element[];
}

const Header: React.FC<HeaderProps> = ({
  icon,
  title,
  isWaitingOffer,
  info,
  descriptionAction,
  headerActions
}) => {
  return (
    <div className="offer-header">
      <div className="offer-header__title-wrapper">
        {icon && (
          <Avatar
            shape="circle"
            size="default"
            className="offer-header__icon"
            src={icon}
          />
        )}
        <h2 className="offer-header__title">{title}</h2>
      </div>
      <div className="offer-header__actions">
        {descriptionAction && (
          <Button size="small" onClick={descriptionAction.onClick}>
            <InfoCircle /> {descriptionAction.text}
          </Button>
        )}
        {isWaitingOffer && (
          <Skeleton.Input active style={{ width: '187px', height: '20px' }} />
        )}
        {info && <InfoBadge title={info} />}
        {headerActions}
      </div>
    </div>
  );
};

interface BodyProps {
  products: {
    title: string;
    description: string;
  }[];
  additionalProducts?: { title: string; content: string[] };
}

const Body: React.FC<BodyProps> = ({ additionalProducts, products }) => (
  <div className="offer-body">
    <div className="offer-body__product">
      {products.map((product) => {
        return (
          <div className="offer-body__product-item" key={product.title}>
            <h4 className="offer-body__product-title">{product.title}</h4>
            <p className="offer-body__product-description">
              {product.description}
            </p>
          </div>
        );
      })}
    </div>
    <div className="offer-body__additional">
      {additionalProducts && (
        <>
          <h4 className="offer-body__additional-title">
            {additionalProducts.title}
          </h4>
          <ul className="offer-body__additional-list">
            {additionalProducts.content.map((content) => (
              <li className="offer-body__additional-list-item" key={content}>
                {content}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  </div>
);

OfferCard.Header = Header;
OfferCard.Body = Body;

export default OfferCard;
