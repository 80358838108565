import './input.scss';

import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';

import AlertCircleIcon from '@/media/icons/alert-circle.svg?react';

export type IInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
  endContent?: JSX.Element;
  isRequired?: boolean;
  classes?: {
    root?: string;
    label?: string;
    error?: string;
  };
  fileName?: string;
  isFrozen?: boolean;
};

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      label,
      classes,
      error,
      isRequired,
      endContent,
      fileName,
      isFrozen,
      ...rest
    },
    forwardedRef
  ) => {
    const endContainerRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

    useEffect(() => {
      // Увеличиваем размер шрифта для safari, чтобы нормально отображались поля
      if (
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        ref.current
      ) {
        ref.current.style.fontSize = '16px';
      }
    }, []);

    const isInputFile = rest.type === 'file';

    const rootClass = ['input', classes?.root ? classes?.root : ''].join(' ');
    const wrapperClass = [
      'input__wrapper',
      isInputFile ? 'input__wrapper--with-file' : '',
      isInputFile && error ? 'input__field--error' : '',
      !label ? 'input__wrapper--without-label' : ''
    ].join(' ');
    const labelClass = [
      classes?.label ? classes.label : '',
      rest.value || fileName ? 'input__label--focused' : ''
    ].join(' ');
    const errorClass = classes?.error ? classes.error : '';
    const fieldClass = [
      rest.className ? rest.className : '',
      error ? 'input__field--error' : '',
      !label ? 'input__field--without-label' : '',
      isInputFile ? 'input__field--file' : '',
      isFrozen ? 'input__field--frozen' : ''
    ].join(' ');

    const inputPadding = endContainerRef.current
      ? `${endContainerRef.current.getBoundingClientRect().width}px`
      : undefined;

    return (
      <div className={rootClass}>
        <label className={wrapperClass} htmlFor={rest.id}>
          <input
            {...rest}
            ref={ref}
            type={isFrozen && isInputFile ? 'text' : rest.type}
            style={{ paddingRight: inputPadding, ...rest.style }}
            placeholder={!label || rest.placeholder ? rest.placeholder : ''}
            value={rest.value}
            className={`input__field ${fieldClass}`}
            onChange={isFrozen ? () => null : rest.onChange}
          />
          {isInputFile && (
            <span
              className="input__file-name"
              style={{
                opacity: fileName ? '1' : '0',
                width: `calc(100% - ${inputPadding})`
              }}
            >
              {fileName ? fileName : 'Нет файла'}
            </span>
          )}
          {label && (
            <p
              style={{ width: endContent ? '75%' : undefined }}
              className={`input__label ${labelClass}`}
            >
              {label}
              {isRequired && <span className="input__require">*</span>}
            </p>
          )}
          {endContent && (
            <div ref={endContainerRef} className="input__end-container">
              {endContent}
            </div>
          )}
        </label>
        {error && (
          <p
            className={`input__error ${errorClass}`}
            onClick={() => ref.current?.focus()}
          >
            <AlertCircleIcon />
            {error}
          </p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
