import './file-upload.scss';

import { notification } from 'antd';
import { useEffect } from 'react';

import { downloadFilesApi } from '@/integrations/index.api';
import FileUploadIcon from '@/media/icons/file-upload.svg?react';
import TrashIcon from '@/media/icons/trash.svg?react';
import {
  bufferToBase64,
  calculateFileSizeInMB,
  convertFileToBase64
} from '@/services/helpers/files';
import { FileType } from '@/types/uploadFiles';

interface IProps {
  title: string;
  file?: FileType;
  helperText?: string;
  error?: string;
  onChange: (file: Partial<FileType>) => void;
  onDelete: () => void;
  isFrozen?: boolean;
  isRequired?: boolean;
  accept?: string;
  allowedExtensions?: string[];
}

const FileUpload: React.FC<IProps> = ({
  title,
  file,
  helperText,
  error,
  onChange,
  onDelete,
  isFrozen,
  isRequired,
  accept,
  allowedExtensions
}) => {
  useEffect(() => {
    // Подгрузка файла и установка его в нужном формате в состояние формы
    const fetchBase64 = async () => {
      if (!(file && 'uuid' in file && file?.uuid)) return;

      try {
        const fileUuid = 'uuid' in file ? (file.uuid as string) : '';
        const response = await downloadFilesApi.downloadBankFile(
          fileUuid || ''
        );

        const base64Response = await fetch(
          `data:image/webp;base64,${response.base64}`
        );
        const blob = await base64Response.blob();
        const imageFile = new File([blob], response.name);

        onChange({
          base64: response.base64,
          name: imageFile.name,
          size: imageFile.size
        });
      } catch (e: unknown) {
        const { message } = e as { message: string };
        if (message?.includes('Try this: ')) {
          const fileLink = message?.split('Try this: ')[1];
          if (fileLink) {
            const response = await fetch(fileLink);
            if (response.ok) {
              const arrayBuffer = await response.arrayBuffer();
              const base64 = bufferToBase64(arrayBuffer);

              const base64Response = await fetch(
                `data:image/webp;base64,${base64}`
              );
              const blob = await base64Response.blob();
              const imageFile = new File([blob], file.name);

              onChange({
                base64: base64,
                name: imageFile.name,
                size: imageFile.size
              });
            }
          }
        }
      }
    };

    if (file && 'uuid' in file && file?.uuid) {
      fetchBase64();
    }
  }, [file]);

  const handleFileChanged = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFile = event.target.files?.[0];
    if (!newFile) {
      onChange({ name: undefined, base64: undefined, size: undefined });
      return;
    }

    const fileExtension = newFile.name.split('.').pop()?.toLowerCase();
    if (allowedExtensions && !allowedExtensions.includes(fileExtension || '')) {
      const extensionsText = allowedExtensions
        .map((item) => `.${item}`)
        .join(', ');
      notification.error({
        message: 'Недопустимое расширение файла!',
        description: `Пожалуйста, загрузите файл в формате ${extensionsText}`
      });
      return;
    }

    try {
      const file = await convertFileToBase64(newFile);
      const base64 = file[1];
      const format = file[0];
      onChange({ name: newFile.name, base64, size: newFile.size, format });
    } catch (error) {
      console.error(error);
      onChange({ name: undefined, base64: undefined, size: undefined });
    }
  };

  const isShowDelete = !isFrozen && file;

  return (
    <div className="file-upload">
      <label>
        {!isFrozen && (
          <input
            className="file-upload__field"
            type="file"
            onChange={handleFileChanged}
            accept={accept}
          />
        )}

        <div
          className={`file-upload__view ${
            error ? 'file-upload__view--error' : ''
          }`}
        >
          <FileUploadIcon />

          <div className="file-upload__file-info">
            <p className="file-upload__title">
              {title}
              {isRequired && <span className="file-upload__require">*</span>}
            </p>
            <p
              className={`file-upload__value ${
                !file ? 'file-upload__value--no-file' : ''
              }`}
            >
              {file?.name ? file.name : 'Выбрать файл'}
              {file?.size && (
                <span className="file-upload__chosen-size">
                  {calculateFileSizeInMB(file.size)} мб
                </span>
              )}
            </p>
          </div>

          {isShowDelete && (
            <button
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
              className="file-upload__delete"
            >
              <TrashIcon />
            </button>
          )}
        </div>
      </label>

      {error ? (
        <p className="file-upload__additional-text file-upload__additional-text--error">
          {error}
        </p>
      ) : (
        helperText && (
          <p className="file-upload__additional-text">{helperText}</p>
        )
      )}
    </div>
  );
};

export default FileUpload;
