import './table.scss';

import { Table, TableProps } from 'antd';
import { Children, ReactElement, ReactNode, cloneElement } from 'react';
import { Link } from 'react-router-dom';

import AllurLoader from '../allur-loader';
import selectComponentClass from './parts/select-component-class';

interface IProps<T> extends Omit<TableProps<T>, 'onRow'> {
  onRowClick?: (row: T) => void;
  rowLink?: (row: T) => string | undefined;
}

const CustomTable = <T extends object>({
  onRowClick,
  rowLink,
  ...props
}: IProps<T>) => {
  // Функция для рекурсивного прохода по `children`
  const processChildren = (children: ReactNode): ReactNode => {
    return Children.map(children, (child) => {
      if (!child || typeof child !== 'object' || !('type' in child)) {
        return child; // Пропускаем не-React элементы
      }

      // Проверяем, является ли элемент `Table.Column`
      const isColumn =
        child.type && (child.type as { name: string }).name === 'Column';

      if (isColumn) {
        const columnProps = child.props;
        const render = columnProps.render;

        return cloneElement(child as ReactElement, {
          render: (value: unknown, record: T, index: number) => {
            const href = rowLink?.(record);
            const originalRender = render
              ? render(value, record, index)
              : value;

            return href ? (
              <Link
                to={href}
                onClick={(e) => {
                  if (onRowClick) e.preventDefault();
                }}
                data-table-link="true"
              >
                {originalRender}
              </Link>
            ) : (
              originalRender
            );
          },
          children: processChildren(columnProps.children)
        });
      }

      return cloneElement(child as ReactElement, {
        children: processChildren((child as ReactElement).props.children)
      });
    });
  };

  return (
    <div className="table">
      <Table<T>
        {...props}
        columns={props.columns?.map((item) => {
          return {
            ...item,
            render: (value, record, index) => {
              const href = rowLink?.(record);
              const render =
                item && item.render ? (
                  item.render(value, record, index)
                ) : (
                  <>{value}</>
                );

              return href ? (
                <Link
                  to={href}
                  onClick={(e) => {
                    if (onRowClick) e.preventDefault();
                  }}
                  data-table-link="true"
                >
                  {render as ReactNode}
                </Link>
              ) : (
                render
              );
            }
          };
        })}
        size="small"
        showSorterTooltip={false}
        rowClassName={`table__row ${onRowClick ? 'table__row--clickable' : ''}`}
        pagination={{
          position: ['bottomLeft'],
          showSizeChanger: true,
          ...props.pagination,
          pageSizeOptions: ['10', '20', '50'],
          // @ts-ignore
          selectComponentClass: selectComponentClass(props.pagination)
        }}
        scroll={{
          x: 'max-content'
        }}
        onRow={(r) => {
          return {
            onClick: () => {
              onRowClick?.(r);
            }
          };
        }}
        loading={{
          spinning: !!props.loading,
          indicator: <AllurLoader fullWidth />
        }}
      >
        {props.children && processChildren(props.children)}
      </Table>
    </div>
  );
};

export default CustomTable;
