import dayjs from 'dayjs';

import { KeysToCamelCase } from '@/types/app';

import { DictTypes } from '../constants';
import { TClientRequest } from '../types/clients';
import { TDataSourceResponses } from '../types/dataSource';
import { TDict } from '../types/store';

export const validateNumberInputValue = (
  value: string,
  maxLength?: number,
  format?: boolean
) => {
  let filteredValue =
    value === '0' ? '0' : value?.toString()?.replace(/[^0-9]/g, '');
  if (maxLength) {
    filteredValue = filteredValue?.slice(0, maxLength);
  }
  if (format) {
    filteredValue = filteredValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return filteredValue;
};

export const findUniqueValuesOfArray = (
  value: any,
  index: number,
  array: any[]
) => {
  return array.indexOf(value) === index;
};

export const setDictionaryOptions = (
  dictSnakeName: DictTypes,
  dicts: TDict[] | null
) => {
  const dictToFind = dicts?.find((dict) => dict.dictType === dictSnakeName);

  const optionsArray = dictToFind?.content.map((option) => {
    return {
      value: option.code,
      label: option.name,
      product_type_uuid: option?.product_type_uuid || option.uuid || null,
      is_active: option?.is_active !== undefined ? option?.is_active : true
    };
  });

  if (dictSnakeName === DictTypes.Dealers) {
    return optionsArray
      ?.filter((data) => data.is_active)
      ?.sort((a, b) => {
        if (a.label.includes('Chevrolet') && !b.label.includes('Chevrolet')) {
          return 1;
        } else if (
          !a.label.includes('Chevrolet') &&
          b.label.includes('Chevrolet')
        ) {
          return -1;
        } else if (a.label.includes('Almaty') && !b.label.includes('Almaty')) {
          return -1;
        } else if (!a.label.includes('Almaty') && b.label.includes('Almaty')) {
          return 1;
        } else if (a.label.includes('Astana') && !b.label.includes('Astana')) {
          return -1;
        } else if (!a.label.includes('Astana') && b.label.includes('Astana')) {
          return 1;
        } else {
          return 0;
        }
      });
  } else if (dictSnakeName === DictTypes.AutoTypes) {
    return optionsArray?.sort((a, b) => {
      if (!a.label.includes('Новое Авто') && b.label.includes('Новое Авто')) {
        return 1;
      } else if (
        a.label.includes('Новое Авто') &&
        !b.label.includes('Новое Авто')
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  } else if (dictSnakeName === DictTypes.EngineTypes) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Бензин')) {
        return -1;
      } else if (b.label.includes('Бензин')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.DocumentTypes) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Удостоверение личности')) {
        return -1;
      } else if (b.label.includes('Удостоверение личности')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.SocialStatuses) {
    return optionsArray?.sort((a, b) => {
      if (a.label.includes('Стандартный профиль')) {
        return -1;
      } else if (b.label.includes('Стандартный профиль')) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  } else if (dictSnakeName === DictTypes.Products) {
    const productTypesDict = findDictByTitle(DictTypes.ProductsTypes, dicts);

    const insuranceProduct = productTypesDict?.content?.find(
      (data) => data.name === 'Страховка'
    );
    return optionsArray?.filter((option) => {
      return option.product_type_uuid === insuranceProduct?.uuid;
    });
  } else {
    return optionsArray;
  }
};

export const setCustomOption = (
  content: {
    code: number;
    name: string;
    uuid: string;
    brand_uuid?: string;
  }[],
  useUuid = false
) => {
  const optionsArray = content.map((data) => {
    return {
      value: useUuid ? data.uuid : data.code.toString(),
      label: data.name
    };
  });
  return optionsArray;
};

export const findDictByTitle = (
  title: DictTypes,
  dicts: TDict[] | null
): TDict => {
  const dictToFind = dicts?.find((dict) => dict.dictType === title);
  return dictToFind as TDict;
};

export const getLastRequestDate = (clintRequests: TClientRequest[]): any => {
  if (clintRequests?.length > 0) {
    return clintRequests[clintRequests.length - 1]?.create_date || '';
  }
};

export const generateRandomSixDiginCode = () => {
  const randomCode = Math.floor(100000 + Math.random() * 900000);
  return randomCode.toString();
};

export const isValidInput = (value: string) => {
  const regex = /^[a-zA-Zа-яА-ЯЁёҚқҒғӘәіІҮүҰұһҺъьӨөҢң\- ]*$/;
  return regex.test(value) || value === '';
};

export const findFinancedOfferDate = (responses: TDataSourceResponses[]) => {
  const filteredArray = [...responses].filter(
    (response) => response.status === 'financed'
  );
  if (filteredArray.length > 0 && filteredArray[0].funded_date) {
    return dayjs(filteredArray[0].funded_date!).format('DD.MM.YYYY');
  } else {
    return '-';
  }
};

/**
 * Конвертирует строку из формата snake_case в формат camelCase.
 *
 * @param {string} str - Строка в snake_case формате.
 * @returns {string} - Строка конвертированная в camelCase формат.
 *
 * @example
 * stringToCamelCase('example_string');
 * // Returns: 'exampleString'
 */
export const stringToCamelCase = (str: string) => {
  return str
    .split('_')
    .map((s, index) =>
      index !== 0 ? `${s[0].toUpperCase()}${s.slice(1)}` : s.toLowerCase()
    )
    .join('');
};

/**
 * Рекурсивно конвертирует ключи объектов, массивов и строки из snake_case в camelCase формат.
 *
 * @template T - Тип передаваемого значения
 * @param {T} input - Передаваемое значение для конвертации. Может быть object, array или string.
 * @returns {KeysToCamelCase<T>} Переданное значение конвертированное в camelCase.
 *
 * @example
 * // Converting an object
 * const obj = {
 *   snake_case_key: 1,
 *   nested_object: { another_snake_case: 2 },
 *   snake_array: [{ test_value: 3 }],
 * };
 * snake_case_toCamelCase(obj);
 * // Returns: {
 * //   snakeCaseKey: 1,
 * //   nestedObject: { anotherSnakeCase: 2 },
 * //   snakeArray: [{ testValue: 3 }]
 * // }
 */
export const snake_case_toCamelCase = <T>(input: T): KeysToCamelCase<T> => {
  if (Array.isArray(input)) {
    return input.map((item) =>
      snake_case_toCamelCase(item)
    ) as KeysToCamelCase<T>;
  }

  if (input && typeof input === 'object' && !Array.isArray(input)) {
    const newObj: Record<string, unknown> = {};
    Object.entries(input).forEach(([key, value]) => {
      newObj[stringToCamelCase(key)] =
        typeof value === 'object' && value !== null
          ? snake_case_toCamelCase(value)
          : value;
    });
    return newObj as KeysToCamelCase<T>;
  }

  if (typeof input === 'string') {
    return stringToCamelCase(input) as KeysToCamelCase<T>;
  }

  return input as KeysToCamelCase<T>;
};
