import {
  BankResponseInterface,
  PosCredUserApplicationInterface,
  ServerPosCredApplicationInterface
} from '../types/slice-types';

export const bankResponsesAdapter = (data: BankResponseInterface[] | null) => {
  return (
    data?.map((bank) => {
      if (bank.offers.length > 0) {
        const foundOffer = bank.offers.findIndex((off) => off.is_accepted) && 0;

        if (foundOffer >= 0) {
          const newBankData = {
            ...bank,
            monthly_payment: bank.offers[foundOffer].monthly_payment,
            interest_rate: bank.offers[foundOffer].loan_rate,
            loan_period: bank.offers[foundOffer].loan_period,
            loan_amount: bank.offers[foundOffer].loan_amount || 0,
            ext_uuid: bank.offers[foundOffer].uuid,
            product_type: bank.offers[foundOffer].product_type || ''
          };
          delete newBankData.offers[foundOffer];
          return newBankData;
        }
      }
      return bank;
    }) || null
  );
};

export const posCredUserAdapter = (
  data: ServerPosCredApplicationInterface
): PosCredUserApplicationInterface => {
  // Convert server data to client-friendly format
  return {
    uuid: data.uuid,
    status: data.status,
    iin: data.client.client_iin,
    fullName: data.client.first_name + ' ' + data.client.last_name,
    orderCode: data.code,
    phoneNumber: data.client.phone_number,
    product: data.product,
    productBank: data.product_bank,
    productDc: data.product_dc,
    productBankUuid: data.product_bank_uuid,
    loanAmount: data.loan_amount || '',
    loanTerm: Number(data.loan_term || 0),
    bankResponse: bankResponsesAdapter(data.responses)
  };
};
