import './style.scss';

import { checkIcon } from '@/constants';

interface IProps {
  activeTab: string;
  setActiveTab: (key: string) => void;
  tabs: { title: string; key: string }[];
  alwaysChecked?: boolean;
}

const CustomTab = ({
  activeTab,
  setActiveTab,
  tabs,
  alwaysChecked
}: IProps) => {
  const activeIndex = tabs.findIndex((item) => item.key === activeTab) || 0;

  return (
    <div className="custom-tab-wrapper">
      <div className="custom-tab">
        {tabs.map((tab, index) => {
          const isTabFinished = index < activeIndex;

          return (
            <div
              key={tab.key}
              className={`custom-tab__tab-item ${
                activeTab === tab.key ? 'custom-tab__tab-item--active' : ''
              } ${isTabFinished ? 'custom-tab__tab-item--done' : ''}`}
              onClick={() => setActiveTab(tab.key)}
            >
              <div>
                {activeTab === tab.key && (
                  <p className="custom-tab__status">Текущий</p>
                )}
                {isTabFinished && (
                  <p className="custom-tab__status custom-tab__status--done">
                    Заполнен
                  </p>
                )}
                <div className="custom-tab__title-block">
                  <span className="custom-tab__number">{index + 1}</span>
                  <span className="custom-tab__title">{tab.title}</span>
                </div>
              </div>
              {(isTabFinished ||
                (alwaysChecked && index !== tabs.length - 1)) && (
                <img className="custom-tab__done-icon" src={checkIcon} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomTab;
