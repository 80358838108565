import './allur-loader.scss';

interface IProps {
  fullWidth?: boolean;
  fullScreen?: boolean;
}

const AllurLoader: React.FC<IProps> = ({ fullWidth, fullScreen }) => {
  const rootClasses = [
    'allur-loader',
    fullWidth ? 'allur-loader--full-width' : '',
    fullScreen ? 'allur-loader--fullscreen' : ''
  ].join(' ');

  return (
    <div className={rootClasses}>
      <div className="allur-loader__text">
        <span>allur</span>
        <span className="allur-loader__small">FINANCE</span>
      </div>
    </div>
  );
};

export default AllurLoader;
