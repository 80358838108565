import { debounce } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  element: HTMLElement | string | null;
}

const useResize = ({ element }: IProps) => {
  const [size, setSize] = useState([0, 0]);

  const handleSizeChanged = () => {
    let newSize = [0, 0];
    if (typeof element === 'string') {
      element = document.querySelector(element) as HTMLElement;
    }
    if (element) {
      const bounds = element.getBoundingClientRect();
      newSize = [bounds.width, bounds.height];
    } else {
      newSize = [window.innerWidth, window.innerHeight];
    }
    setSize(newSize);
  };
  const getSize = debounce(handleSizeChanged, 200);

  useEffect(() => {
    getSize();
    window.addEventListener('resize', getSize);
    return () => {
      window.removeEventListener('resize', getSize);
    };
  }, [element]);

  return size;
};

export default useResize;
