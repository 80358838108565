import { Buffer } from 'buffer';
import * as crypto from 'crypto-js';

export const encrypt = (data: string) => {
  const key =
    process.env.REACT_APP_USE_ENC_KEY == 'true'
      ? `${process.env.REACT_APP_ENC_KEY}_${new Date()
          .getFullYear()
          .toString()}`
      : new Date().getFullYear().toString();
  const initializationVector = crypto.lib.WordArray.random(16);
  const hashedEncryptionKey = crypto.SHA256(key);
  let cipher;
  try {
    cipher = crypto.AES.encrypt(data, hashedEncryptionKey, {
      iv: initializationVector
    });
  } catch {
    cipher = '';
  }

  return Buffer.from(
    `${initializationVector.toString()}:${Buffer.from(cipher?.toString())}`
  ).toString('base64');
};

export const decrypt = (data: string) => {
  const key = `${process.env.REACT_APP_ENC_KEY}_${new Date()
    .getFullYear()
    .toString()}`;
  const [initializationVectorAsHex, encryptedDataAsHex] = Buffer.from(
    data,
    'base64'
  )
    .toString()
    .split(':');
  const initializationVectorD = crypto.enc.Hex.parse(initializationVectorAsHex);
  const hashedEncryptionKeyD = crypto.SHA256(key);
  const decipher = crypto.AES.decrypt(
    encryptedDataAsHex,
    hashedEncryptionKeyD,
    {
      iv: initializationVectorD
    }
  );

  return decipher.toString(crypto.enc.Utf8);
};
