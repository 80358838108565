import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { apiConnector } from '@/integrations/api.connector';
import { dispatch, useAppSelector } from '@/store';
import { tokenValidate } from '@/store/auth.slice';
import { getUserAccount } from '@/store/userInfo.slice';

import { ROUTES } from './routes';

const UNAUTHORIZED_ROUTES = ['/login', '/reset_password', '/approvements'];

const CheckAuth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserAuth } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(tokenValidate());
    setInterval(async () => {
      await apiConnector.refreshToken();
    }, 180000);
  }, []);

  useEffect(() => {
    if (!isUserAuth) {
      const shouldRedirect = UNAUTHORIZED_ROUTES.find((item) =>
        location.pathname.includes(item)
      );
      if (!shouldRedirect) navigate(ROUTES.login);
    } else if (isUserAuth) dispatch(getUserAccount());
  }, [isUserAuth]);

  useEffect(() => {
    dispatch(tokenValidate());
  }, []);

  return <Outlet />;
};

export default CheckAuth;
