import './button.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { forwardRef } from 'react';

interface IProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  children: string | React.ReactNode;
  size?: 'small' | 'big';
  variant?: 'filled-red' | 'filled-green' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  fullWidth?: boolean;
  isReversed?: boolean;
  isSquare?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      children,
      isLoading,
      size,
      variant = 'outlined',
      fullWidth,
      isReversed,
      isSquare,
      ...props
    },
    ref
  ) => {
    const buttonClass = [
      'button',
      props.className ? ` ${props.className}` : '',
      size ? ` button--${size}` : '',
      variant ? ` button--${variant}` : '',
      fullWidth ? ` button--full-width` : '',
      isReversed ? ' button--reversed' : '',
      isSquare ? ' button--square' : ''
    ].join('');

    return (
      <button
        {...props}
        type={props.type}
        ref={ref}
        id={props.id}
        className={buttonClass}
        disabled={props.disabled || isLoading}
        onClick={props.onClick}
      >
        {children}
        {isLoading && (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
