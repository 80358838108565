import './style.scss';

import { Avatar, Tooltip } from 'antd';

import { STATUSES_ENUM } from '../../constants/statuses';

interface OfferTooltipProps {
  title: string;
  status?: keyof typeof STATUSES_ENUM;
  content?: string;
  options?: {
    src: string;
    description: string;
  }[];
}

const OfferTooltip = ({
  title,
  options,
  status,
  content
}: OfferTooltipProps) => {
  return (
    <Tooltip
      className="offer-tooltip"
      title={
        <div className="offer-tooltip__wrapper">
          <p className={`offer-tooltip__title offer-tooltip__title--${status}`}>
            {title}
          </p>
          {options?.map((option, i) => (
            <div className="offer-tooltip__avatar-wrapper" key={i}>
              <Avatar
                size={'small'}
                shape="circle"
                className="offer-tooltip__avatar"
                style={{ backgroundColor: 'white' }}
                src={option.src}
              />
              <p className="offer-tooltip__date">{option.description}</p>
            </div>
          ))}
        </div>
      }
    >
      <div
        className={`offer-tooltip__content offer-tooltip__content--${status}`}
      >
        {content}
      </div>
    </Tooltip>
  );
};

export default OfferTooltip;
