import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConnector } from '@/integrations/api.connector';
import { snake_case_toCamelCase } from '@/services';

import {
  SystemNotificationResponse,
  TNotificationServerResponse
} from '../types/notifications';
import { TNotificationsStore } from '../types/store';

export const startNotification = createAsyncThunk(
  'admin/system-notifications',
  async () => {
    const res = await apiConnector.startNotification();
    return res?.payload;
  }
);

const initialState: TNotificationsStore = {
  notifications: [],
  systemNotifications: [],
  lastNotificationsChecked: true,
  showNotifications: false
};

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    onToggleShowNotifications: (state) => {
      state.showNotifications = !state.showNotifications;
    },
    addNewNotification: (
      state,
      action: PayloadAction<TNotificationServerResponse>
    ) => {
      state.lastNotificationsChecked = false;
      state.notifications = [action.payload, ...state.notifications];
    },
    setSystemNotifications: (
      state,
      action: PayloadAction<SystemNotificationResponse[]>
    ) => {
      state.systemNotifications = action.payload.map((item) => ({
        ...item,
        moduleUuid: item.module_uuid
      }));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(startNotification.fulfilled, (state, action) => {
      state.systemNotifications = action.payload
        ? snake_case_toCamelCase(action.payload)
        : state.systemNotifications;
    });
  }
});

export const {
  onToggleShowNotifications,
  addNewNotification,
  setSystemNotifications
} = notificationsSlice.actions;
