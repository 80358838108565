import { Camera } from '@mediapipe/camera_utils';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import {
  FACEMESH_TESSELATION,
  HAND_CONNECTIONS,
  Holistic,
  Results
} from '@mediapipe/holistic';
import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import { useAppDispatch, useAppSelector } from '@/store';
import { setShowBiometricVerificating } from '@/store/app.slice';

const BiometricScan = () => {
  const dispatch = useAppDispatch();
  const { orderApp } = useAppSelector((state) => state.newOrder);
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [holisticState, setHolisticState] = useState<Holistic | null>(null);
  useEffect(() => {
    const holistic = new Holistic({
      locateFile: (file: string) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
      }
    });
    holistic.setOptions({
      selfieMode: true,
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      refineFaceLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5
    });
    holistic.onResults(onResults);
    // holistic.onResults(onResults);

    setHolisticState(holistic);
  }, []);

  useEffect(() => {
    if (
      orderApp !== null &&
      !orderApp?.biometric_validated &&
      orderApp?.status !== 'sms_verificating'
    ) {
      dispatch(setShowBiometricVerificating(true));
    } else {
      dispatch(setShowBiometricVerificating(false));
    }
  }, [orderApp]);

  const handleStartBiometric = () => {
    if (
      typeof webcamRef.current !== 'undefined' &&
      webcamRef.current !== null
    ) {
      if (!webcamRef.current?.video) return;
      const camera = new Camera(webcamRef.current.video, {
        onFrame: async () => {
          if (!webcamRef.current?.video) return;
          await holisticState?.send({ image: webcamRef.current.video });
        },
        width: 640,
        height: 480
      });
      camera.start();
    }
  };

  const onResults = (results: Results) => {
    if (!webcamRef.current?.video || !canvasRef.current) return;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    if (canvasCtx == null) throw new Error('Could not get context');
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = 'source-in';
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    canvasCtx.globalCompositeOperation = 'source-over';
    // drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS,
    //   {color: '#00FF00', lineWidth: 4});
    // drawLandmarks(canvasCtx, results.poseLandmarks,
    //   {color: '#FF0000', lineWidth: 2});
    drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION, {
      color: '#C0C0C070',
      lineWidth: 1
    });
    drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS, {
      color: '#CC0000',
      lineWidth: 5
    });
    drawLandmarks(canvasCtx, results.leftHandLandmarks, {
      color: '#00FF00',
      lineWidth: 2
    });
    drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS, {
      color: '#00CC00',
      lineWidth: 5
    });
    drawLandmarks(canvasCtx, results.rightHandLandmarks, {
      color: '#FF0000',
      lineWidth: 2
    });
    canvasCtx.restore();
  };

  return (
    <div
      style={{
        background: 'rgb(20 20 20 / 88%)',
        height: '100vh',
        display: 'none',
        // Позжк поставить: display: showBiometricVerificating ? 'flex' : 'none'
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Button
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px'
        }}
        onClick={() => dispatch(setShowBiometricVerificating(false))}
      >
        Назад
      </Button>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%',
          height: '800px',
          overflow: 'hidden',
          borderRadius: '50%',
          position: 'relative',
          border: '2px solid red'
        }}
      >
        {/* <Webcam
          ref={webcamRef}
          videoConstraints={videoConstraints}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        /> */}
      </div>
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          textAlign: 'center',
          zIndex: 9,
          width: 1200,
          height: 800
        }}
      />
      <Button style={{ zIndex: '2000' }} onClick={() => handleStartBiometric()}>
        Снимок
      </Button>
    </div>
  );
};

export default BiometricScan;
