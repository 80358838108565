import './Layout.scss';

import { FC, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import SelectDealerModal from '@/components/App/SelectDealer/SelectDealerModal';
import useResize from '@/hooks/useResize';
import { useAppDispatch, useAppSelector } from '@/store';
import { getUserMenu, setDealer } from '@/store/userInfo.slice';

import { AllurLoader } from '../Shared/ui';
import NotificationsSideBar from './Notifications/NotificationsSideBar';
import ErrorTemplate from './errors/error-template';
import { Header, Navbar } from './widgets';

export const Layout: FC = () => {
  const dealers = useAppSelector((state) => state.dict.activeDealersOption);
  const { dealer } = useAppSelector((state) => state.userInfo);
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [showSelectDealerModal, setSelectDealerModal] = useState<boolean>(true);

  const [navbarWidth] = useResize({ element: '.Layout .wrapper' });

  const dealerCode = localStorage.getItem('dealer');

  useEffect(() => {
    if (dealerCode) {
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: string;
          }
        | undefined =
        dealers &&
        dealers.find((_dealer) => _dealer.code.toString() == dealerCode);
      setSelectDealerModal(false);

      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
  }, [dealers]);

  useEffect(() => {
    if (isUserAuth && !dealerCode) {
      setSelectDealerModal(true);
    }
  }, [isUserAuth, dealerCode]);

  useEffect(() => {
    if (dealer?.value) dispatch(getUserMenu(dealer.value));
  }, [dispatch, dealer?.value]);

  const showSelectDealerModalToggle = () => {
    setSelectDealerModal(!showSelectDealerModal);
  };

  return (
    <div className="Layout">
      <Navbar />

      <div
        className="wrapper"
        style={{
          // @ts-ignore
          '--navbar-width': `${navbarWidth}px`
        }}
      >
        <Header />

        <main>
          <ErrorBoundary fallback={<ErrorTemplate type="oops" />}>
            <Suspense fallback={<AllurLoader fullScreen={true} />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </main>
      </div>

      <NotificationsSideBar />

      {isUserAuth && showSelectDealerModal && (
        <SelectDealerModal show={showSelectDealerModalToggle} />
      )}
    </div>
  );
};
