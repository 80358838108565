import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addProductsDictionaryMapper } from '@/services/mappers/addProductsDictionaryMapper';
import { stockbondsProductsDictionaryMapper } from '@/services/mappers/stockbondsProductsDictionaryMapper';

import { DictTypes } from '../constants';
import { apiConnector } from '../integrations/api.connector';
import {
  TAddProductDictionaryResponse,
  TDict,
  TDictStore,
  TProductInsuranceServerResponse
} from '../types/store';

export const getProductsDictionary = createAsyncThunk(
  'dict/getProductsDictionary',
  async (): Promise<TAddProductDictionaryResponse[]> => {
    try {
      return await apiConnector.getProductsDictionary();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getProductInsurance = createAsyncThunk(
  'dict/getProductInsurance',
  async (): Promise<TProductInsuranceServerResponse[]> => {
    try {
      return await apiConnector.getProductInsurance();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getPromosByModel = createAsyncThunk(
  'dict/getPromosByModel',
  async (modelId: string) => {
    try {
      return await apiConnector.getPromosByModel(modelId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getOptimizedDictionaries = createAsyncThunk(
  'dict/getOptimizedDictionaries',
  async (): Promise<TDict[]> => {
    try {
      const dictTypes = Object.values(DictTypes).filter(
        (item) => item !== 'Cities'
      );
      const dicts = await apiConnector.getOptimizedDictionaries(dictTypes);
      const cities = await apiConnector.getCitiesForFilter();
      return dicts
        .map((dict: any) => ({
          dictType: dict.name,
          content: dict.content
        }))
        .concat([
          {
            dictType: 'Cities',
            content: cities
          }
        ]);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getActiveDealers = createAsyncThunk(
  'dict/getActiveDealers',
  async (): Promise<any> => {
    try {
      const data = await apiConnector.getDealersAll();
      return data.filter((dealer) => dealer?.is_active);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getDealersByUserId = createAsyncThunk(
  'dict/getDealersByUserID',
  async (userId: string): Promise<any> => {
    try {
      return await apiConnector.getDealerByUserUuid(userId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TDictStore = {
  dicts: null,
  dictStatus: 'idle',
  usersDealers: [],
  additionalProductsDict: null,
  paymentTypes: null,
  stockBoundsProductsDict: null,
  otherUsers: null,
  isLoading: false,
  addProductsInsurances: null,
  statusForFilterOptions: null,
  regionOption: null,
  cityOption: null,
  cityForFilterOption: null,
  villageOption: null,
  activeDealersOption: null
};

export const dictSlice = createSlice({
  name: 'dict',
  initialState: initialState,
  reducers: {
    setProductDictsToInitial: (state) => {
      state.stockBoundsProductsDict = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptimizedDictionaries.pending, (state) => {
        state.dictStatus = 'pending';
        state.isLoading = true;
      })
      .addCase(getOptimizedDictionaries.rejected, (state) => {
        state.dictStatus = 'rejected';
        state.isLoading = false;
      })
      .addCase(getOptimizedDictionaries.fulfilled, (state, action) => {
        state.dictStatus = 'fulfilled';
        state.isLoading = false;
        state.dicts = action.payload;
        const statusesResults = action.payload.find(
          (dict) => dict.dictType === DictTypes.Statuses
        );
        const paymentTypesResults = action.payload.find(
          (dict) => dict.dictType === DictTypes.RepaymentTypes
        );
        if (paymentTypesResults) {
          state.paymentTypes = paymentTypesResults.content.map((item) => ({
            value: item.uuid,
            label: item.name,
            code: item.code
          }));
        }
        if (statusesResults) {
          state.statusForFilterOptions = statusesResults.content.map(
            (item) => ({
              value: item.uuid,
              label: item.name,
              code: item.code
            })
          );
        }
        state.dicts = action.payload;
      })

      .addCase(getProductsDictionary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsDictionary.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getProductsDictionary.fulfilled,
        (state, action: PayloadAction<TAddProductDictionaryResponse[]>) => {
          state.additionalProductsDict = addProductsDictionaryMapper(
            action.payload
          ).filter((product) => product.productType === 'Доп. продукт');
        }
      )

      .addCase(getDealersByUserId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDealersByUserId.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getDealersByUserId.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          if (action.payload?.length > 0) {
            state.usersDealers = action.payload.map(
              (userDealer: {
                dealer: {
                  city_uuid: string;
                  code: number;
                  is_active: boolean;
                  name: string;
                  sold_brands: string;
                  uuid: string;
                };
                user_uuid: string;
                uuid: string;
              }) => {
                return {
                  value: userDealer.dealer?.uuid || '',
                  code: userDealer.dealer.code || '',
                  label: userDealer.dealer?.name || ''
                };
              }
            );
          }
        }
      )

      .addCase(getActiveDealers.fulfilled, (state, action) => {
        state.activeDealersOption = action.payload.map(
          (dealer: {
            city_uuid: string;
            code: number;
            is_active: boolean;
            name: string;
            sold_brands: string;
            uuid: string;
            has_commercial: boolean;
          }) => {
            return {
              value: dealer?.uuid || '',
              code: dealer.code || '',
              label: dealer?.name || '',
              has_commercial: dealer?.has_commercial || false
            };
          }
        );
      })
      .addCase(getPromosByModel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPromosByModel.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPromosByModel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stockBoundsProductsDict = stockbondsProductsDictionaryMapper(
          action.payload
        ).filter((item) => item.uuid);
      })

      .addCase(getProductInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductInsurance.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(
        getProductInsurance.fulfilled,
        (state, action: PayloadAction<TProductInsuranceServerResponse[]>) => {
          state.isLoading = false;
          state.addProductsInsurances = action.payload;
        }
      );
  }
});

export const { setProductDictsToInitial } = dictSlice.actions;
