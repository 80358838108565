import './autocomplete.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';
import { debounce } from '@/services/helpers/debounce';

import type { IInputProps } from '../input';
import Input from '../input';
import OutsideClickHandler from '../outside-click-handler';
import useAutoComplete from './useAutocomplete';

interface IProps extends Omit<IInputProps, 'onChange'> {
  options: IAutoCompleteOptions[];
  value: string;
  onChange: (value: string) => void;
}

const Autocomplete = forwardRef<HTMLInputElement, IProps>(
  ({ options, isFrozen, ...rest }, forwardedRef) => {
    const [topPosition, setTopPosition] = useState(false);
    const [focused, setFocused] = useState(false);
    const [focusTriggered, setFocusTriggered] = useState(false);

    const [lastValue, setLastValue] = useState('');

    const handleChange = (value: string) => {
      rest.onChange(value);
    };

    const handleFocus = (value = '') => {
      setFocused(true);
      setLastValue(rest.value);
      rest.onChange(value);
    };

    const handleBlur = (value = '') => {
      setFocused(false);
      if (!rest.value && !value) rest.onChange(lastValue);
      setLastValue('');
    };

    const {
      bindInput,
      bindOptions,
      bindOption,
      isBusy,
      suggestions,
      selectedIndex,
      clearSuggestions
    } = useAutoComplete({
      value: rest.value,
      lastValue,
      onChange: handleChange,
      defaultOptions: options,
      delay: 200,
      source: (search) =>
        options.filter((option) => {
          const label = option.label.toLowerCase();
          return label.includes(search.toLowerCase());
        }),
      handleFocus,
      handleBlur
    });

    const handleScroll = useCallback(
      debounce(() => {
        if (bindOptions.ref.current) {
          // Disable setting position when popup opened
          if (focused) return;

          const listTopPosition =
            bindOptions.ref.current.getBoundingClientRect().top;
          if (window.innerHeight - 260 < listTopPosition) {
            setTopPosition(true);
          } else {
            setTopPosition(false);
          }
        }
      }, 300),
      [focused]
    );

    useEffect(() => {
      handleScroll();
      const main = document.querySelector('main');
      main?.addEventListener('scroll', handleScroll);
      return () => {
        main?.removeEventListener('scroll', handleScroll);
      };
    }, [bindOptions.ref, handleScroll]);

    const handlePopupToggle = () => {
      if (isFrozen) return;

      if (focused) {
        handleBlur();
        clearSuggestions();
      } else {
        handleFocus();
      }
    };

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!focused) return;
          clearSuggestions();
          handleBlur();
        }}
      >
        <div className="autocomplete">
          <Input
            ref={forwardedRef}
            {...rest}
            value={bindInput.value}
            placeholder={lastValue}
            endContent={
              <span
                className={`autocomplete__arrow ${
                  focused ? 'autocomplete__arrow--rotated' : ''
                }`}
              >
                <ChevronDownIcon />
              </span>
            }
            onChange={bindInput.onChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && focused) e.preventDefault();
              if (e.key === 'Tab') handleBlur();
              bindInput.onKeyDown(e);
              rest.onKeyDown?.(e);
            }}
            onFocus={(e) => {
              setFocusTriggered(true);
              handlePopupToggle();
              rest.onFocus?.(e);
            }}
            onClick={(e) => {
              setTimeout(() => {
                if (!focusTriggered) {
                  handlePopupToggle();
                }
                setFocusTriggered(false);
              }, 0);
              rest.onClick?.(e);
            }}
            error={!focused ? rest.error : undefined}
            isFrozen={isFrozen}
            autoComplete="off"
          />
          <div
            className={`autocomplete__popup ${
              focused ? 'autocomplete__popup--shown' : ''
            } ${topPosition ? 'autocomplete__popup--to-top' : ''}`}
          >
            <ul {...bindOptions} className="autocomplete__list">
              {isBusy ? (
                <div className="autocomplete__list-loading">
                  <Spin indicator={<LoadingOutlined spin />} size="large" />
                </div>
              ) : (
                suggestions.map((option, index) => (
                  <li
                    className={`autocomplete__item ${
                      selectedIndex === index
                        ? 'autocomplete__item--selected'
                        : ''
                    }`}
                    key={option.label + option.value}
                    {...bindOption}
                  >
                    {option.label}
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;

export interface IAutoCompleteOptions {
  value: string;
  label: string;
}
