import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TUiControllerSlice = {
  showModalCreateDbTable: boolean;
  showModalCreateSimpleDict: boolean;
  showModalCreateOrEditModel: boolean;
  showModalCreateOrEditDealer: boolean;
  showModalCreateOrEditProduct: boolean;
  showModalCreateOrEditPromoProductModel: boolean;
  showModalCreateOrEditSalePoint: boolean;
  showFlashLogModal: boolean;
  showModalCreateOrEditBank: boolean;
  showUserModal: boolean;
  showClientModal: boolean;
  showPositionModal: boolean;
  showRolesActionModal: boolean;
};

const initialState: TUiControllerSlice = {
  showModalCreateDbTable: false,
  showModalCreateSimpleDict: false,
  showModalCreateOrEditModel: false,
  showModalCreateOrEditDealer: false,
  showModalCreateOrEditProduct: false,
  showModalCreateOrEditPromoProductModel: false,
  showModalCreateOrEditSalePoint: false,
  showFlashLogModal: false,
  showModalCreateOrEditBank: false,
  showUserModal: false,
  showClientModal: false,
  showPositionModal: false,
  showRolesActionModal: false
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setShowModalCreateDbTable: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateDbTable = action.payload;
    },
    setShowModalCreateOrEditPromoProductModel: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showModalCreateOrEditPromoProductModel = action.payload;
    },
    setShowModalFlashLog: (state, action: PayloadAction<boolean>) => {
      state.showFlashLogModal = action.payload;
    },
    setShowModalCreateOrEditBank: (state, action: PayloadAction<boolean>) => {
      state.showModalCreateOrEditBank = action.payload;
    }
  }
});
export const {
  setShowModalCreateDbTable,
  setShowModalCreateOrEditPromoProductModel,
  setShowModalFlashLog,
  setShowModalCreateOrEditBank
} = adminSlice.actions;
